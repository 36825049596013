export default {
  "previous_tour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lancement du site web du 2022 Tournée Techno"])},
  "l_ribbon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transistor"])},
  "r_ribbon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le Van"])},
  "presents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CyberPower présente"])},
  "first_headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dîner et apprendre avec des leaders de la technologie"])},
  "first_paragraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CyberPower, ainsi que d'autres entreprises technologiques, parcourront le Canada cet été pour rencontrer leurs clients et leur faire part de leurs dernières solutions et offres technologiques. Chaque événement en plein air comprendra de la nourriture, des boissons et des articles promotionnels gratuits."])},
  "first_headline_2023": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dîner et apprendre avec des leaders de la technologie"])},
  "first_paragraph_2023": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CyberPower, ainsi que d'autres entreprises technologiques, parcourront le Canada cet été pour rencontrer leurs clients et leur faire part de leurs dernières solutions et offres technologiques. Chaque événement en plein air comprendra de la nourriture, des boissons et des articles promotionnels gratuits."])},
  "first_headline_2024": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le dernier chapitre du Transistor Tournée Techno"])},
  "first_paragraph_2024": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rejoignez CyberPower, ainsi que d'autres leaders de l'industrie, lors du dernier chapitre du Transistor Tournée Techno.  La tournée traversera le Canada cet été pour rencontrer les clients et partager leurs plus récentes solutions et offres technologiques.  Chaque événement comprendra de la nourriture, des boissons et des cadeaux gratuits."])},
  "countdown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PROCHAIN ARRÊT"])},
  "visit_vendor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visitez"])},
  "participating_vendors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FOURNISSEURS PARTICIPANTS"])},
  "supporting_vendors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SOUTIEN AUX FOURNISSEURSS"])},
  "more_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PLUS D'INFOS SUR LES FOURNISSEURS "])},
  "kickoff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La tournée commence le 25 mai 2022"])},
  "second_headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous prenons la route pour vous aider sur le chemin de la réussite."])},
  "second_p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CyberPower, ainsi que d'autres entreprises technologiques, visiteront plus de 30 villes à travers le Canada avec 62 événements spéciaux pour soutenir les revendeurs informatiques canadiens."])},
  "second_p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CyberPower sera accompagné par Acer, Aruba, Buffalo, Cradlepoint, Ergotron, Fujitsu, Kaspersky, Lenovo, Logitech, Microsoft 365, Promethean, StarTech, Targus, Vendasta, et WatchGuard."])},
  "second_p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CyberPower est fière de collecter des fonds pour soutenir la Fondation Terry Fox et sa mission de financement de la recherche sur le cancer."])},
  "donate_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fondation Terry Fox"])},
  "donate_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://fr.terryfox.ca/3044/page/15694"])},
  "donate_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apprenez-en plus sur la"])},
  "donate_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["et comment faire un don."])},
  "donate_combined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apprenez-en plus sur la <a href='https://fr.terryfox.ca/3044/page/15694' target='_blank'>Fondation Terry Fox</a> et comment faire un don."])},
  "kickoff_2023": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coup d'envoi, le 20 juin 2023"])},
  "second_headline_2023": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le Transistor Tournée Techno est en marche."])},
  "second_p1_2023": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CyberPower, ainsi que d'autres entreprises technologiques, s'arrêteront dans tout le Canada pour rencontrer les revendeurs informatiques canadiens."])},
  "second_p2_2023": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CyberPower sera accompagné par Aruba Instant-On, PFU, une entreprise de Ricoh, Lenovo, Targus, OpenText et WatchGuard."])},
  "second_p3_2023": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De plus, CyberPower collectera des fonds pour soutenir le sentier transcanadien et sa mission qui consiste à financer le sentier national du Canada, le plus long réseau de sentiers récréatifs polyvalents au monde."])},
  "donate_link_2023": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["le sentier transcanadien"])},
  "donate_url_2023": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.canadahelps.org/en/pages/transistor-tech-tour-2023/"])},
  "donate_1_2023": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour en savoir plus sur"])},
  "donate_2_2023": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["et sur la façon de faire un don."])},
  "donate_combined_2023": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour en savoir plus sur <a href='https://www.canadahelps.org/en/pages/transistor-tech-tour-2023/' target='_blank'>le sentier transcanadien</a> et sur la façon de faire un don."])},
  "kickoff_2024": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Début le 15 mai 2024"])},
  "second_headline_2024": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le Transistor Tournée Techno est en cours"])},
  "second_p1_2024": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ne manquez pas votre chance de participer au dernier Transistor Tournée Techno."])},
  "second_p2_2024": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CyberPower est accompagné par Aruba Instant-On, Lenovo, WatchGuard, OpenText, Kingston, PFU, Ricoh company, N-Able et TeamViewer."])},
  "second_p3_2024": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cet été, CyberPower recueillera des fonds pour Arbres Canada, qui se consacre à la plantation et à l'entretien d'arbres dans les milieux ruraux et urbains, dans toutes les provinces du Canada."])},
  "donate_link_2024": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["le sentier transcanadien"])},
  "donate_url_2024": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.justgiving.com/page/noah-jacobs-1704301202610"])},
  "donate_combined_2024": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour en savoir plus sur <a href='https://www.justgiving.com/page/noah-jacobs-1704301202610' target='_blank'>Arbres Canada</a> et comment faire un don."])},
  "what_youll_see": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CE QUE VOUS ALLEZ VOIR"])},
  "maps_cities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CARTES ET VILLES"])},
  "maps_headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transistor Tournée Techno"])},
  "maps_paragraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le Transistor Tournée Techno débutera à St. John's T.-N.-L., Canada, le 25 mai. Chaque événement de la tournée aura lieu en plein air et présentera de nouveaux produits ou des offres de vendeurs, ainsi que de la nourriture, des boissons et des articles promotionnels gratuits. Recherchez le van du Transistor Tournée Techno  dans votre ville bientôt !"])},
  "maps_cities_2023": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CARTES ET VILLES"])},
  "maps_headline_2023": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transistor Tournée Techno"])},
  "maps_paragraph_2023": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette année, Le Transistor Tournée Techno débute le mardi 20 juin, à Montréal. Chaque événement présentera de nouveaux produits ou des offres de vendeurs. Il y aura aussi de la nourriture, des boissons et des articles promotionnels gratuits. Rejoignez-nous lorsque nous visiterons votre ville!"])},
  "maps_cities_2024": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CARTES ET VILLES"])},
  "maps_headline_2024": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transistor Tournée Techno"])},
  "maps_paragraph_2024": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette année, le Transistor Tournée Techno débute le mercredi 15 mai. Chaque événement proposera de nouveaux produits ou des offres de la part des vendeurs, ainsi que de la nourriture, des boissons et des articles promotionnels gratuits. Rejoignez-nous lorsque nous visiterons votre ville!"])},
  "wrap_headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CE SERA TOUT POUR CETTE ÉDITION!"])},
  "wrap_subhead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merci de nous rejoindre en 2023!"])},
  "wrap_paragraph_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Au cas où vous auriez manqué le voyage, voici un récapitulatif rapide de la route passionnante qui nous a réunis et des produits fantastiques qui nous distinguent. Jusqu'à la prochaine fois, n'oubliez pas de nous suivre sur"])},
  "wrap_paragraph_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LinkedIn"])},
  "wrap_paragraph_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["et"])},
  "wrap_paragraph_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instagram"])},
  "wrap_paragraph_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" pour connaître les dates et heures du 2024 Tournée Techno de CyberPower."])},
  "news_social": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NOUVELLES ET SOCIAL"])},
  "news_social_headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suivez Transistor le Van"])},
  "news_social_paragraph_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suivez Transistor le Van sur "])},
  "news_social_paragraph_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LinkedIn"])},
  "news_social_paragraph_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["et"])},
  "news_social_paragraph_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instagram"])},
  "news_social_paragraph_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" pour rester au courant de ses déplacements à travers le pays. Restez connecté avec le Tournée Techno pour ne rien manquer des vitrines technologiques vedettes, des nouveaux produits et des offres spéciales."])},
  "news_social_headline_2023": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mise à jour sur le Transistor Tournée Techno"])},
  "news_social_paragraph_1_2023": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suivez Le Transistor Tournée Techno sur"])},
  "news_social_paragraph_2_2023": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LinkedIn"])},
  "news_social_paragraph_3_2023": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["et"])},
  "news_social_paragraph_4_2023": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instagram"])},
  "news_social_paragraph_5_2023": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pour rester au courant de ses déplacements à travers le pays. Restez connecté avec le Tournée Techno pour ne rien manquer des vitrines technologiques vedettes, des nouveaux produits et des offres spéciales."])},
  "news_social_headline_2024": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mise à jour sur le Transistor Tournée Techno"])},
  "news_social_paragraph_1_2024": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suivez Le Transistor Tournée Techno sur"])},
  "news_social_paragraph_2_2024": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LinkedIn"])},
  "news_social_paragraph_3_2024": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["et"])},
  "news_social_paragraph_4_2024": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instagram"])},
  "news_social_paragraph_5_2024": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["et restez informé des innovations technologiques, des nouveaux produits et des offres spéciales. N'oubliez pas d'utiliser le hashtag #TechTour2024 lorsque nous nous rendrons dans votre ville!"])},
  "end_tour_headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Nous en avons terminé"])},
  "end_tour_paragraph_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le dernier Transistor Tournée Techno a eu lieu. Ce fut un voyage incroyable au cours des trois dernières années. Nous espérons que tous nos partenaires ont apprécié la tournée autant que nous, les vendeurs. Au cas où vous l'auriez manqué, voici un récapitulatif de tout ce que nous avons fait en 2024. N'oubliez pas de nous suivre sur "])},
  "end_tour_paragraph_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pour voir ce que l'avenir nous réserve."])},
  "news_social_cta_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suivez-nous"])},
  "news_social_video": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regardez la vidéo récapitulative du 2024 Transistor Tournée Techno"])},
  "back_to_main_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour à la page d'accueil"])},
  "days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jours"])},
  "hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heures"])},
  "minutes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minutes"])},
  "seconds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secondes"])},
  "vendor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fournisseur"])},
  "ultimate_ally": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre allié ultime au power"])},
  "footer_trademark": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["© 2023 Cyber Power Systems (USA), Inc. CyberPower est une marque déposée de Cyber Power Systems (USA), Inc. Tous droits réservés."])},
  "footer_trademark_2024": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["©2024 Cyber Power Systems (USA), Inc. CyberPower est une marque déposée de Cyber Power Systems (USA), Inc. Tous droits réservés."])},
  "brands": {
    "Acer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acer est l’une des plus grandes entreprises de TIC au monde fortement axée sur l’innovation et permettant un monde où le matériel, les logiciels et les services fusionneront les uns avec les autres pour ouvrir de nouvelles possibilités pour les consommateurs et les entreprises. Notre objectif principal est de briser les barrières entre le monde et la technologie"])},
    "Aruba": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le portefeuille Aruba Instant On offre une solution réseau hautement performante et sécurisée. Aruba Instant On vous garantit des performances ultra rapides, une sécurité améliorée ainsi qu’une configuration et une gestion simplifiées. Vous obtenez également un matériel et un logiciel de pointe capable de prendre en charge toute future expansion à un prix abordable. "])},
    "Buffalo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buffalo est un leader mondial des solutions de stockage sécurisées et abordables. Buffalo propose des solutions de stockage fiables, faciles à utiliser et évolutives, associées à un service client exceptionnel."])},
    "Cradlepoint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les solutions edge sans fil de Cradlepoint libèrent la puissance des réseaux cellulaires LTE et 5G pour offrir à votre WAN la portée illimitée, la fiabilité constante et l’agilité en temps réel dont il a besoin pour suivre le rythme de vos activités. Connectez des sites fixes et temporaires, des véhicules, des unités mobiles et des appareils IoT, où que vous soyez. Simplement, en toute sécurité et sans fil."])},
    "CyberPower": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CyberPower fabrique des équipements de protection de puissance haut de gamme.  Ces solutions de gestion de l'alimentation éprouvées et fiables répondent aux exigences les plus critiques de PME, sièges sociaux, secteur de la santé, gouvernement et les écoles. Votre allié ultime au power!"])},
    "Ergotron": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Ergotron utilise le mouvement pour améliorer la façon dont les gens travaillent, apprennent, jouent et prennent soin des autres. En mettant l’accent sur les soins de santé, l’éducation, le bureau général et les solutions personnalisées, nous nous appuyons sur des principes de conception centrés sur l’humain et la technologie du mouvement™ pour créer des environnements qui aident les gens à s’épanouir. Bureaux debout, supports écrans"]), _normalize(["Ergotron "])])},
    "Fujitsu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les numériseurs Fujitsu permettent aux entreprises et aux particuliers d'accroître leur productivité, de réaliser des économies de temps et d'argent, d'organiser et structurer leurs documents, et de partager des informations, ce qui en fait souvent le point de départ des grands projets de transformation numérique."])},
    "Kaspersky": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous sommes une entreprise mondiale de cybersécurité qui s'appuie sur une expertise approfondie en matière de renseignement sur les menaces, de sécurité et de formation. Après 25 ans d'existence, nous continuons à innover avec des solutions qui combinent les plus récentes fonctionnalités de protection des points d'extrémité, de chasse aux menaces, de détection et de réponse aux points d'extrémité (EDR) et de détection et de réponse gérées (MGR), etc. Découvrez comment nous pouvons vous assurer un avenir plus sécuritaire."])},
    "Lenovo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lenovo, une entreprise globale qui réalise un chiffre d’affaires de 60 milliards de dollars est présente sur 180 marchés à travers le monde. Axés autour d’une vision audacieuse visant à fournir une technologie plus intelligente pour tous, ils développent actuellement des technologies qui changent le monde et qui alimentent (via des appareils et des infrastructures) et permettent (via des solutions, des services et des logiciels) à des millions de clients, chaque jour et ensemble, de créer une société numérique plus inclusive, plus fiable et plus durable, pour tous et partout."])},
    "Logitech": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les solutions de visioconférence avancées de Logitech permettent aux équipes de collaborer, se connecter, et partager bien plus que les fichiers et les écrans; élaborez les idées au tableau blanc et faites participer tous les intervenants avec Logitech et le logiciel de votre choix : c’est la technologie la plus simple et la plus sûre qui soit."])},
    "Microsoft 365": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Microsoft Teams Rooms comble le fossé entre les personnes travaillant à distance et celles au bureau avec une solution de salle de réunion qui permet à chacun d'être vu, entendu et de participer pleinement de n’importe où."])},
    "OpenText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opentext est une fière entreprise canadienne, qui compte plus de 25 000 employés dans le monde. Grâce à des acquisitions stratégiques telles que Carbonite, Webroot et Zix, OpenText Cybersecurity fournit des solutions de sécurité complètes pour les entreprises et les partenaires de toutes tailles. De la prévention à la détection, en passant par la réponse, la reprise, l'investigation et la conformité, notre plateforme unifiée de bout en bout aide les clients à renforcer leur cyberrésilience grâce à un portefeuille de sécurité globale."])},
    "PFU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les scanneurs RICOH permettent aux organisations et aux individus d'améliorer leur productivité, d'économiser du temps et de l'argent, d'organiser et de structurer leurs documents, de partager des informations et constituent souvent la première étape de grands projets de transformation numérique."])},
    "Promethean": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les écrans interactifs et les logiciels Promethean pour l'éducation et le lieu de travail sont à l'origine de percées pour les enseignants, les étudiants et les collègues du monde entier. L'apprentissage et la collaboration sont essentiels pour progresser dans n'importe quel domaine. Notre mission est de les transformer pour le mieux, d'aider nos clients à tirer le meilleur parti de la technologie de pointe et de les responsabiliser pour l'avenir. Faisons quelque chose de brillant!"])},
    "StarTech.com": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["StarTech.com facilite la connectivité informatique en proposant une large gamme d'accessoires fiables conçus pour répondre aux besoins de performances techniques rigoureux des professionnels de l'informatique."])},
    "Targus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Depuis plus de trente-cinq ans, Targus développe des solutions adaptées pour fournir les outils nécessaires à votre réussite. Notre présence globale et notre engagement qualité garantissent que nous avons les compétences et l'expérience nécessaires pour répondre à vos besoins en termes de performance, de style et de protection. "])},
    "Vendasta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vendasta est une plateforme de e-commerce électronique intégrale qui aide nos partenaires à commercialiser, vendre, facturer, compléter et livrer des solutions digitales aux PME à travers le Canada. Vendasta s'associe à des fournisseurs de canaux informatiques, des distributeurs, des infogérants et des revendeurs à travers l'Amérique du Nord pour les aider dans leur transformation digitale!"])},
    "WatchGuard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WatchGuard fournit une sécurité de pointe pour les réseaux et les terminaux, du Wi-Fi sécurisé, l'authentification à plusieurs facteurs, et des produits et services d'intelligence réseau, WatchGuard permet à plus de 250 000 TPE et PME du monde entier de protéger leurs actifs les plus précieux, incluant plus de 10 millions de terminaux."])}
  }
}