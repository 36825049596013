<template>
  <div>
    <section class="countdown">
      <div class="ribbon">
        <div class="ribbon-inset">
          <div class="left-side"></div>
          <div class="logo">
            <img v-if="this.$i18n.locale == 'en'" src="../assets/tech-tour-2024-badge-eng.png" />
            <img class="fr-logo" v-if="this.$i18n.locale == 'fr'" src="../assets/tech-tour-2024-badge-fr.png" />
          </div>
          <div class="right-side"></div>
        </div>
      </div>
      <!-- <div class="background-blockout">
        <div></div>
        <div class="white"></div>
      </div> -->
    </section>

    <section class="social">
      <div class="container">
        <div>
            <div class="video">
              <video controls
                  src="https://dl4jz3rbrsfum.cloudfront.net/video/CyberPower-Tech-Tour-Canada-Roadshow-2024.mp4"
                  poster="https://dl4jz3rbrsfum.cloudfront.net/video/CyberPower-Tech-Tour-Canada-Poster-2024.jpg"
                  type="video/mp4">
              </video>
              <h5 class="section-label">{{ $t("news_social_video") }}</h5>
          </div>
        </div>
        <div class="description">
          <h2>{{ $t("end_tour_headline") }}</h2>
          <p>
            {{ $t("end_tour_paragraph_1") }}

            <a href="https://www.linkedin.com/in/transistor-tech-tour-6654b2224/"
              target="_BLANK">{{ $t("news_social_paragraph_2_2024") }}</a>
            {{ $t("news_social_paragraph_3_2024") }}
            <a href="https://www.instagram.com/transistortechtour/"
              target="_BLANK">{{ $t("news_social_paragraph_4_2024") }}</a>
            {{ $t("end_tour_paragraph_2") }}
            <br />
          </p>
          <span class="label-dash">&#8211;</span>
          <span class="section-label">{{ $t("news_social") }}</span>
          <h3>{{ $t("news_social_cta_1") }}</h3>
          <div class="social-links">
            <a href="https://www.linkedin.com/in/transistor-tech-tour-6654b2224/" target="_BLANK">
              <img loading="lazy" src="../assets/social-linkedin.png" alt="LinkedIn logo" />
            </a>
            <a href="https://www.instagram.com/transistortechtour/" target="_BLANK">
              <img loading="lazy" src="../assets/social-instagram.png" alt="Instagram logo" />
            </a>
          </div>
        </div>
      </div>
    </section>

    <section class="footer">
      <div class="container">
        <div class="branding">
          <img loading="lazy" src="../assets/cp-white.png" alt="CyberPower logo" />
        </div>
        <div>
          <p>{{ $t("footer_trademark_2024") }}</p>
          <ul class="copy-links">
            <!-- TODO - add anchor hrefs -->
            <!-- <li><a href="/company/terms">Terms &amp; Conditions</a></li>
            <li><a href="/company/privacy">Privacy Policy</a></li> -->
          </ul>
        </div>
      </div>
    </section>
  </div>
</template>


<style lang="scss" scoped>
  img {
    max-width: 100%;
  }

  //display these images only on mobile screens
  .mobile-only {
    margin-bottom: 20px;

    @media (min-width: 800px) {
      display: none;
    }
  }

  .background-blockout {
    width: 100%;
    height: 300px;

    display: block;
    position: relative;

    .white {
      background-color: rgba(255, 255, 255, 0.75);
    }

    @media (min-width: 800px) {
      height: 550px;
      position: absolute;
      height: 100%;
      display: grid;
      grid-template-rows: 1fr;
      grid-template-columns: 1fr 1fr;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 3;
    }
  }

  .section-label {
    margin-bottom: 16px;
    text-transform: uppercase;
  }

  .countdown {
    min-height: 450px;
  }

    .video {
      video {
      border: 1px solid #000000;
      width: 100%;
      margin-bottom: 20px;
      }

      @media (min-width: 800px) {
        padding-left: 50px;
        padding-right: 50px;
      }
  }


  .footer {
    padding-top: 20px;
    background: #3c3c3c;
    font-size: 0.75em;

    .container {
      display: grid;
      grid-template-columns: 1fr;
      gap: 40px;
    }

    .copy-links {
      margin-bottom: 0;
      margin-left: -30px;
      padding-top: 8px;
      padding-bottom: 20px;

      li {
        padding-right: 10px;
        display: inline-block;

        a {
          color: #fff;
          text-decoration: none;
        }
      }
    }

    img {
      width: 190px;
    }

    p {
      margin-bottom: 0;
      color: #ffffff;
    }

    @media (min-width: 800px) {
      .container {
        grid-template-columns: .75fr 1.25fr;
      }
    }
  }

  .home {
    display: grid;
    border: solid 1px;
    height: 100%;
    width: 100%;
    align-content: center;
    justify-content: center;

    img {
      max-width: 300px;
    }
  }

  .instagram-media {
    background: #fff;
    border: 0;
    border-radius: 3px;
    box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.5), 0 1px 10px 0 rgba(0, 0, 0, 0.15);
    margin: 1px;
    max-width: 540px;
    min-width: 326px;
    padding: 0;
    width: 99.375%;
    width: -webkit-calc(100% - 2px);
    width: calc(100% - 2px);
  }

  .social {
    min-height: 450px;
    padding-top: 30px;
    padding-bottom: 30px;

    .promo-logo {
      width: 360px;
    }

    .fr-logo {
      width: 360px !important;
    }

    a {
      color: #D50032;
    }

    h2 {
      margin-bottom: 32px;
      color: #000000;
      font-weight: 500;
      font-size: 2.625rem;
      line-height: 2.75rem;
    }

    .container {
      display: grid;

      @media (min-width: 800px) {
        grid-template-columns: 1.4fr 1fr;
      }
    }

    .description {
      h3 {
        color: #D50032;
      }
      p {
        margin-bottom: 32px;
      }
    }

    .promo-logo {
      display: block;
      margin: 0 auto;
    }

    .social-links {
      img {
        margin-right: 16px;
        width: 58px;
      }
    }

    @media (min-width: 800px) {
      padding-top: 20px;
      padding-bottom: 100px;
    }
  }

  .slide-box {
    display: flex;
    justify-content: space-between;
  }

  @media (min-width: 576px) and (max-width: 767.98px) {
    .slide-box img {
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 50%;
    }
  }

  @media (min-width: 768px) and (max-width: 991.98px) {
    .slide-box img {
      -ms-flex: 0 0 33.3333%;
      flex: 0 0 33.3333%;
      max-width: 33.3333%;
    }
  }

  @media (min-width: 992px) {
    .slide-box img {
      -ms-flex: 0 0 25%;
      flex: 0 0 25%;
      max-width: 25%;
    }
  }

  .carousel-caption {
    background-color: rgba(0, 0, 0, 0.5);
    padding: 20px;
    border-radius: 0.5rem;
  }

  .ttour-enter-from {
    opacity: 0;
  }

  .ttour-enter-active {
    transition: all 1s ease-out;
    transition-delay: 3s;
  }

  .ttour-leave-to {
    opacity: 0;
  }

  .ttour-leave-active {
    transition: all 1s ease-in;
    transition-delay: 3s;
  }

  .language-bar {
    height: 60px;

    .language-toggle {
      position: fixed;
      right: 75px;
      top: 0;
      padding: 0.5em 0.5em;
      text-align: center;
      z-index: 5;
      cursor: pointer;

      @media (min-width: 500px) {
        padding: 0.5em 2em;
      }

      span {
        background-color: #cccccc;
        padding: 10px;
        display: inline-block;
        border-radius: 20px;
        box-shadow: inset 0px 2px 4px 2px rgba(0, 0, 0, 0.5);
        font-size: 12px;
        font-weight: 600;
        text-transform: uppercase;
        color: #fff;
      }
    }
  }
</style>
<!-- IL8N example: <p>{{ $t("message") }}</p> -->
