import { createApp } from 'vue'
import App from './App.vue'
import router from './router' 

import VueGtag from "vue-gtag"
import VueGoogleMaps from '@fawmi/vue-google-maps'

import './assets/styles/main.css';
import i18n from './i18n';
import 'bootstrap/dist/css/bootstrap.min.css';


const app = createApp(App);

app.use(i18n);
app.use(router);


app.use(VueGtag, {
    appName: "Transistor Tech Tour",
    pageTrackerScreenviewEnabled: true,
    config: {
        id: "G-BGF4XY10D7"
    }
}, router);

app.use(VueGoogleMaps, {
    load: {
        key: 'AIzaSyB2vwvSDTKlVA-R12mTTohzQjwC13574d4',
        // language: 'de',
    }
});


app.mount('#app');
