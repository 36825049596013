<template>
  <div>
    <div class="container top-bar">
      <div class="tour-link"><img loading="lazy" src="./assets/red-arrow-transparent.png" alt=""><span>PREVIOUS TECH TOUR YEARS: </span>
      <a href="/Techtour2022" class="more-label">2022</a>&thinsp;
      <a href="/Techtour2023" class="more-label">2023</a>&thinsp;
      <a href="/Techtour2024" class="more-label">2024</a>
      </div>
      <div class="language-toggle" @click="changeLanguage()">
        <span>{{ currentLanguage }}: {{ $i18n.locale }}</span>
      </div>
    </div>
    <!--div
      class="modal fade"
      id="languageModal"
      tabindex="-1"
      aria-labelledby="LanguageModal"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="languageModalLabel">
              Bientôt en ligne...
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <p>La traduction française sera bientôt disponible!</p>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div-->
    <router-view v-slot="{ Component }">
      <component :is="Component"></component>
    </router-view>
  </div>
</template>
<script>
import { Modal } from "bootstrap";

export default {
  data() {
    return {
      currentLanguage: "English",
      isModalVisible: false,
    };
  },
  setup() {},
  methods: {
    changeLanguage() {
      if (this.currentLanguage == "English") {
        this.currentLanguage = "French";
        this.$i18n.locale = "fr";
        // let myModal = new Modal(document.getElementById("languageModal"));
        // myModal.show();
      } else {
        this.currentLanguage = "English";
        this.$i18n.locale = "en";
      }
    },
  },
};
</script>
<style lang="scss">
@import url("https://cloud.typography.com/6736232/7379832/css/fonts.css");

#app {
  height: 100%;
  font-family: Gotham, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
.navigation {
  display: none;
}
/* route transitions */
.route-enter-from {
  opacity: 0;
}
.route-enter-active {
  transition: all 1s ease-out;
}
.route-leave-to {
  opacity: 0;
}
.route-leave-active {
  transition: all 1s ease-in;
}

.language-toggle {
  right: 75px;
  top: 0;
  padding: 0.5em 0.5em;
  text-align: center;
  z-index: 5;
  cursor: pointer;

  @media (min-width: 500px) {
    padding: 0.5em 2em;
  }

  span {
    background-color: #cccccc;
    padding: 10px;
    display: inline-block;
    border-radius: 20px;
    box-shadow: inset 0px 2px 4px 2px rgba(0, 0, 0, 0.5);
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    color: #fff;
  }
}

  @media (min-width: 800px) {
    .language-toggle {
      position: fixed;
    }
  }

  .top-bar {
    margin-bottom: 20px;
    display: grid;
    grid-template-columns: 1fr;
    gap: 15px;

      @media (min-width: 800px) {
          grid-template-columns: 1fr 1fr 200px;
      }
  }

  @media (max-width: 799px) {
    .top-bar {
      padding-top: 20px;
      display: grid;

      .tour-link {
        img {
          width: 35px;
        }
      }
    }
  }

.tour-link {
  padding-top: 30px;
  span {
    font-size: 0.875rem;
    font-weight: 500;
  }
  a {
    font-size: 0.875rem;
    font-weight: 500;
    color: #D50032;
  }
  img {
    width: 70px;
    margin-top: -35px;
  }
}
.ribbon {
  z-index: 4;
  position: absolute;
  background: $cp-red;
  height: 120px;
  padding-top: 40px;
  left: 0;
  right: 0;
  top: 200px;
  transform: skewY(-12deg);
  a {
    color: #fff;
    &:hover {
      color: #f5f5f5;
    }
  }
  .ribbon-inset {
    left: 0;
    right: 0;
    height: 60px;
    position: absolute;
    background: #272727;
    height: 3em;
    display: grid;
    grid-template-columns: auto;
    @media (min-width: 660px) {
      grid-template-columns: auto 400px auto;
    }

    .left-side,
    .right-side {
      color: #ffffff;
      text-transform: uppercase;
      font-weight: 600;
      letter-spacing: 0.2em;
      font-size: 1em;
      margin-top: 0.8em;
      opacity: 0;
      width: 0;

      display: none;
      @media (min-width: 660px) {
        display: inline-block;
        width: auto;
        opacity: 1;
      }
      @media (min-width: 900px) {
        margin-top: 0em;
        font-size: 2em;
      }
    }
    .left-side {
      justify-self: right;
    }
    .right-side {
      justify-self: left;
    }
    .logo {
      transform: skewY(12deg);
      position: relative;
      display: grid;
      align-items: center;
      img {
        margin: 0 auto;
        transform: translateY(-50%);
        width: 60%;
        @media (min-width: 800px) {
          width: 90%;
        }
      }
      .fr-logo {
        margin: 0 auto;
        transform: translateY(-50%);
        width: 60%;
        @media (min-width: 800px) {
          width: 90%;
        }
      }
    }
  }
}
</style>
<!-- <transition name="route" mode="out-in"> -->
<!-- </transition> -->
